// PaymentDetails.js
import React from 'react'
import { FaCcVisa, FaCcMastercard, FaCcAmex } from 'react-icons/fa'

const PaymentDetails = (props) => {
  // Normalize transactions data structure
  const transactionNodes = (props.transactions?.edges || []).map(
    (edge) => edge.node
  )

  const transaction = transactionNodes.find(
    ({ kind, status }) => kind === 'authorization' && status === 'success'
  )
  const { paymentDetails, receipt } = transaction || {}
  const creditCardBrand = paymentDetails?.creditCardBrand
  const creditCardNumber = paymentDetails?.creditCardNumber

  const Icon = {
    mastercard: FaCcMastercard,
    visa: FaCcVisa,
    american_express: FaCcAmex,
  }[creditCardBrand?.toLowerCase()]

  return (
    <div className="flex text-sm items-center">
      {receipt?.payment_method_details?.type === 'klarna' ? (
        'Klarna'
      ) : (
        <>
          {Icon ? <Icon /> : null}
          <span className="ml-2">{creditCardNumber}</span>
        </>
      )}
    </div>
  )
}

export default PaymentDetails
