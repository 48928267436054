import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCartStateContext } from '../../contexts/CartContext'
import { getVariantImages } from '../../utils/shopify'

import CustomerInformation from './CustomerInformation'
import LineItems from './LineItems'
import TrackingDetails from './TrackingDetails'

const Order = (props) => {
  const { order } = props

  const { client } = useCartStateContext()
  const [imagesByVariantId, setImages] = useState({})

  const normalizedOrder = {
    ...order,
    transactions: order.transactions?.edges
      ? order.transactions.edges.map((edge) => edge.node)
      : order.transactions || [],
  }

  useEffect(() => {
    getVariantImages(
      client,
      order.line_items.map((li) => li.variant_id)
    ).then((variantsWithImage) =>
      setImages(
        variantsWithImage.reduce(
          (acc, v) => ({ ...acc, [v.variant_id]: v.image }),
          {}
        )
      )
    )
  }, [order])

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div className="flex-1 mb-8">
        <h2 className="text-depalmaDarkGray text-lg">
          Order #{order.order_number}
        </h2>
        <h3 className="text-xl">
          <FormattedMessage
            id="orderStatus.thank-you"
            defaultMessage="Thank you {firstName} {lastName}"
            values={{
              firstName: order.customer?.first_name,
              lastName: order.customer?.last_name,
            }}
          />
        </h3>

        <TrackingDetails order={normalizedOrder} />

        <CustomerInformation order={normalizedOrder} />
      </div>

      <div className="flex-1 mb-8">
        <LineItems
          order={normalizedOrder}
          imagesByVariantId={imagesByVariantId}
        />
      </div>
    </div>
  )
}

export default Order
