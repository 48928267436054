import React from 'react'
import { FormattedMessage } from 'react-intl'
import Address from './Address'
import PaymentDetails from './PaymentDetails'

const CustomerInformation = (props) => {
  const { order } = props
  const {
    billing_address,
    customer,
    shipping_address,
    shipping_lines,
    transactions,
    note_attributes,
  } = order

  // Get VAT number from note_attributes
  const vatNumber = note_attributes?.find((note) => note.name === 'vatNumber')
    ?.value
  // Get company name from shipping address
  const companyName = shipping_address?.company

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 text-sm">
      <div className="flex-1">
        <h4 className="averta-bold mt-4">
          <FormattedMessage
            id="orderStatus.contact-information"
            defaultMessage="Contact information"
          />
        </h4>
        <p>{order.customer.email}</p>

        {/* Add company information if available */}
        {(companyName || vatNumber) && (
          <>
            <h4 className="averta-bold mt-4">
              <FormattedMessage
                id="orderStatus.company-information"
                defaultMessage="Company information"
              />
            </h4>
            {companyName && <p>{companyName}</p>}
            {vatNumber && (
              <p>
                <FormattedMessage
                  id="orderStatus.vat-number"
                  defaultMessage="VAT Number: {vatNumber}"
                  values={{ vatNumber }}
                />
              </p>
            )}
          </>
        )}

        <h4 className="averta-bold mt-4">
          <FormattedMessage
            id="orderStatus.shipping-address"
            defaultMessage="Shipping address"
          />
        </h4>
        <Address address={shipping_address} />

        <h4 className="averta-bold mt-4">
          <FormattedMessage
            id="orderStatus.shipping-method"
            defaultMessage="Shipping method"
          />
        </h4>
        <p>{shipping_lines?.length ? shipping_lines[0].code : null}</p>
      </div>
      <div className="flex-1">
        <h4 className="averta-bold mt-4">
          <FormattedMessage
            id="orderStatus.payment-method"
            defaultMessage="Payment method"
          />
        </h4>
        <PaymentDetails transactions={transactions} />

        <h4 className="averta-bold mt-4">
          <FormattedMessage
            id="orderStatus.billing-address"
            defaultMessage="Billing address"
          />
        </h4>
        <Address address={billing_address} />
      </div>
    </div>
  )
}

export default CustomerInformation
